<template>
  <div class="product-form">
    <FormProduct form-type="add" @onValidateSuccess="doSave" :on-loading="onLoading" />
  </div>
</template>
<script>
import FormProduct from './Form.vue'
import ProductService from "@/service/product.service";
import base64Converter from "@/pages/Helper/base64";
import TokenService from "@/service/token.service";
export default {
  components: {
    FormProduct,
  },
  data() {
    return {
      onLoading: false
    }
  },
  methods: {
    async doSave(payload) {
      // Submit here
      this.onLoading = true
      let { product, status, slug } = payload
      if (product) {
        let ecommerces = [], testimonials = [], type = []
        for (const [k, v] of Object.entries(product.ecommerce)) {
          ecommerces.push(v.item.id)
        }

        if (product.testimonials.length > 0) {
          for (const [k, v] of Object.entries(product.testimonials)) {
            let { isShow, image, ...arrData } = v
            testimonials.push({
              ...arrData,
              image: image[0] && image.length > 0 ? await base64Converter(image[0]) : null
            })
          }
        }

        if (product.type.length > 0) {
          for (const [k, v] of Object.entries(product.type)) {
            let { id } = v
            type.push(id)
          }
        }

        payload = {
          user_id: (TokenService.getUser()).id,
          "product_title": product.name,
          "qty_in_box": product.qty_in_box,
          "parent_product_category_id": product.categoryParent.id.toString(),
          slug,
          "weight": product.weight ? product.weight : null,
          "description": product.description,
          "product_type_id": type,
          "desktop_image": await base64Converter(product.images.desktop[0]),
          "mobile_image": await base64Converter(product.images.mobile[0]),
          "ecommerce_id": ecommerces,
          alt_desktop_image: product.images.alt_desktop_image ? product.images.alt_desktop_image : null,
          alt_mobile_image: product.images.alt_mobile_image ? product.images.alt_mobile_image : null,
          main_image_alt_next: product.images.alt ? product.images.alt : null,
          product_category_id: product.category.id ? product.category.id : null,
          order: product.order ? product.order : null,
          // video_url: product.video_url ? 'https://youtu.be/4ApqUMIPl0s?si=' + product.video_url : null,
          video_url: product.video_url ? product.video_url : null,
          // excerpt_video: product.excerpt_video ? product.excerpt_video : null,
          // thumbnail: product.thumbnail ? product.thumbnail : null,
          "testimony": testimonials,
          "seoInformation": {
            "meta_title": product.meta.title,
            "meta_description": product.meta.description,
            "meta_keyword": product.meta.keywords.toString(),
            image: (product.meta.image && product.meta.image.length > 0) ?
              await base64Converter(product.meta.image[0]) : null,
            alt_image: product.meta.alt_image ? product.meta.alt_image : null,
          },
          status: status
        }

        ProductService.postProduct(payload).then((rsp) => {
          this.$swal({
            title: "Data berhasil disimpan",
            showCloseButton: true,
          }).then(() => {
            this.$router.push({ path: '/products/list' })
          });
        }).catch((e) => {
          if (e.response?.status === 409) {
            this.$notify({
              type: "warn",
              title: "Error Message",
              text: "Data Sudah Ada",
            });
          } else {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message || "Terjadi Kesalahan",
            });
          }
        }).finally(() =>
          this.onLoading = false)
      }
    }
  }
}
</script>
