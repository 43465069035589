<template>
  <div class="product-form">
    <FormPost form-type="add" @onValidateSuccess="doSave" :on-loading="onLoading" />
  </div>
</template>
<script>
import FormPost from "./Form.vue";
import ResellerService from "@/service/reseller.service";
import base64Converter from "@/pages/Helper/base64";
import slugify from "@/pages/Helper/slug";
import moment from "moment";

export default {
  components: {
    FormPost,
  },
  data() {
    return {
      onLoading: false
    }
  },
  methods: {
    async doSave(payload) {
      this.onLoading = true
      let { reseller } = payload
      if (reseller) {
        let payload = {
          customer_id: reseller.customer.id ? reseller.customer.id : null,
          manager_id: reseller.manager.id ? reseller.manager.id : null,
          name: reseller.name ? reseller.name : null,
          address: reseller.address ? reseller.address : null,
          phone: reseller.phone ? '+62' + reseller.phone : null,
          merchant_name: reseller.merchant_name ? reseller.merchant_name : null,
          no_ktp: reseller.no_ktp ? parseInt(reseller.no_ktp) : 0,
          no_npwp: reseller.no_npwp ? parseInt(reseller.no_npwp) : 0,
          ktp_image: (reseller.ktp_image && reseller.ktp_image.length > 0) ?
            await base64Converter(reseller.ktp_image[0]) : null,
          npwp_image: (reseller.npwp_image && reseller.npwp_image.length > 0) ?
            await base64Converter(reseller.npwp_image[0]) : null,
          tnc: 1,
          newsletter: reseller.newsletter ? 1 : 0,
          status: reseller.status.value ? reseller.status.value : null,
          area: reseller.area ? reseller.area : null,
        }

        ResellerService.postReseller(payload).then((rsp) => {
          this.$swal({
            title: "Data berhasil disimpan",
            showCloseButton: true,
          }).then(() => {
            this.$router.push({ path: '/reseller-submission/list' })
          });
        }).catch((e) => {
          if (e.response?.status === 409) {
            this.$notify({
              type: "warn",
              title: "Error Message",
              text: "Data Sudah Ada",
            });
          } else {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message || "Terjadi Kesalahan",
            });
          }
        }).finally(() =>
          this.onLoading = false)
      }
    },
    dateFormat(date) {
      return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD')
    }
  },
};
</script>
