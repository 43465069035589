<template>
  <div class="product-form">
    <FormPost form-type="edit" :detail-data="form" @onValidateSuccess="doSave" />
  </div>
</template>
<script>
import FormPost from "./Form.vue";
import resellerService from "@/service/reseller.service";
import base64Converter from "@/pages/Helper/base64";
import slugify from "@/pages/Helper/slug";
import moment from "moment";

export default {
  data() {
    return {
      form: {
        id: this.$route.params.id,
        title: "",
      },
      optionStatus: [
        {
          label: 'Pending',
          value: 'pending'
        },
        {
          label: 'Banned',
          value: 'banned'
        },
        {
          label: 'Active',
          value: 'active'
        }
      ],
    };
  },
  components: {
    FormPost,
  },
  mounted() {
    this.loadItems(this.$route.params.id)
  },
  methods: {
    async doSave(payload) {
      // Submit here

      this.onLoading = true
      let { reseller } = payload
      if (reseller) {
        let payload = {
          customer_id: reseller.customer.id ? reseller.customer.id : null,
          manager_id: reseller.manager.id ? reseller.manager.id : null,
          name: reseller.name ? reseller.name : null,
          address: reseller.address ? reseller.address : null,
          phone: reseller.phone ? '+62' + reseller.phone : null,
          merchant_name: reseller.merchant_name ? reseller.merchant_name : null,
          no_ktp: reseller.no_ktp ? parseInt(reseller.no_ktp) : 0,
          no_npwp: reseller.no_npwp ? parseInt(reseller.no_npwp) : 0,
          // ktp_image: (reseller.ktp_image && reseller.ktp_image.length > 0) ?
          //   await base64Converter(reseller.ktp_image[0]) : null,
          // npwp_image: (reseller.npwp_image && reseller.npwp_image.length > 0) ?
          //   await base64Converter(reseller.npwp_image[0]) : null,
          tnc: 1,
          newsletter: reseller.newsletter ? 1 : 0,
          status: reseller.status.value ? reseller.status.value : null,
          area: reseller.area ? reseller.area : null,
        }

        resellerService.patchReseller(this.$route.params.id, payload).then((rsp) => {
          this.$swal({
            title: "Data berhasil disimpan",
            showCloseButton: true,
          }).then(() => {
            this.$router.push({ path: '/reseller-submission/list' })
          });
        }).catch((e) => {
          if (e.response?.status === 409) {
            this.$notify({
              type: "warn",
              title: "Error Message",
              text: "Data Sudah Ada",
            });
          } else {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message || "Terjadi Kesalahan",
            });
          }
        }).finally(() =>
          this.onLoading = false)
      }
    },
    loadItems(id) {
      resellerService.getResellerById(id)
        .then(async ({ data: { data } }) => {
          this.form = {
            ...data,
            newsletter: data.newsletter == 1 ? true : false,
            phone: data.phone ? data.phone.replace('+62', '') : null,
            status: this.optionStatus.find((r) => r.value == data.status),
            ktp_image: (data.ktp_image && data.ktp_image) ? [await this.convertURLtoFile(this.loadSrcImage(data.ktp_image))] : null,
            ktpSrc: (data.ktp_image && data.ktp_image) ? this.loadSrcImage(data.ktp_image) : '',
            npwp_image: (data.npwp_image && data.npwp_image) ? [await this.convertURLtoFile(this.loadSrcImage(data.npwp_image))] : null,
            npwpSrc: (data.npwp_image && data.npwp_image) ? this.loadSrcImage(data.npwp_image) : '',
          }
        }).catch((e) => {
        })
    },
    loadSrcImage(url) {
      return this.$api_name + '/' + url
    },
    async convertURLtoFile(url) {
      const response = await fetch(url);
      if (response.status != 200) {
        return "";
      }
      const data = await response.blob();
      const filename = url.split("/").pop(); // url 구조에 맞게 수정할 것
      const metadata = { type: `image/jpeg` };
      return new File([data], filename, metadata);
    },
  },
};
</script>
