<template>
  <div>
    <div class="row align-items-center justify-content-between mb-4">
      <div class="col-auto">
        <!-- BEGIN page-header -->
        <h1 class="page-header">All Banner</h1>
        <!-- END page-header -->
      </div>
      <div class="col-auto mb-3" v-if="add">
        <router-link to="/sections/banner/add">
          <a class="btn btn-outline-primary"> Add New Banner</a>
        </router-link>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import tokenService from "@/service/token.service";
export default {
  data() {
    return {
      permisions: tokenService.getPermission()
    }
  },
  computed: {
    add: function () {
      if (this.permisions.find((r) => r == 'BannerManagement Add')) {
        return true
      }
      return false;
    },
  },
}
</script>
