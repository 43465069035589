<template>
  <div>
    <div class="row align-items-center justify-content-between mb-4">
      <div class="col-auto">
        <!-- BEGIN page-header -->
        <h1 class="page-header">All Role</h1>
        <!-- END page-header -->
      </div>
      <div class="col-auto mb-3" v-if="add">
        <router-link to="/users/manage/role/add">
          <a class="btn btn-outline-primary">Add New Role</a>
        </router-link>
      </div>
    </div>

    <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="totalRecords"
      :isLoading.sync="isLoading" :pagination-options="{
        enabled: true,
        dropdownAllowAll: false,
        setCurrentPage: setCurrentPage,
        perPage: serverParams.take,
      }" :columns="columns" :rows="rows" :sort-options="{
  enabled: false,
  initialSortBy: { field: 'role_name', type: 'asc' },
}" styleClass="vgt-table" slot="outsideBody">
      <template slot="column-filter" slot-scope="{ column, updateFilters }">
        <input ref="role_name" class="form-control" placeholder="Search roles" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'role_name'
          " @input="(value) => updateFilters(column, $refs.role_name.value)" />
      </template>
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'action'">
          <div v-if="props.row.role_name == 'Superadmin'" class="row g-2 align-items-center justify-content-end">
            <div class="col-auto">
              <div class="btn btn-lg text-gray">
                Readonly
              </div>
            </div>
          </div>
          <div v-else class="row g-2 align-items-center justify-content-end">
            <div class="col-auto" v-if="edit">
              <router-link :to="`/users/manage/role/edit/${props.row.id}`" class="btn btn-lg text-dark" v-b-tooltip.hover
                title="Edit">
                <i class="fas fa-pencil"></i>
              </router-link>
            </div>
            <div class="col-auto" v-if="deleteRole">
              <button type="button" class="btn btn-lg text-dark" v-b-tooltip.hover title="Move to Trash"
                @click.prevent="showAlertConfirm(props.row.id)">
                <i class="fas fa-trash"></i>
              </button>
            </div>
          </div>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import RoleService from "@/service/role.service";
import tokenService from "@/service/token.service";
import _ from 'lodash';

export default {
  data() {
    return {
      setCurrentPage: this.$store.state.role.serverParams.skip / this.$store.state.role.serverParams.take == 0 ?
        1 : this.$store.state.role.serverParams.skip / this.$store.state.role.serverParams.take + 1,
      permisions: tokenService.getPermission(),
      isLoading: false,
      columns: [
        {
          label: "Role Name",
          field: "role_name",
          thClass: "text-nowrap",
          tdClass: "align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Action",
          field: "action",
          width: "200px",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
      ],
      rows: [],
      totalRecords: 0,
      serverParams:  this.$store.state.role.serverParams,
      errorCode: "",
      errorMessage: "",
      error: "",
      forbidenError: false,
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    updateParams(newProps) {
      this.$store.state.role.serverParams = Object.assign({},  this.$store.state.role.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ skip: (params.currentPage - 1) * params.currentPerPage });
      this.loadItems();
    },
    onPerPageChange(params) {
      this.updateParams({ skip: 0 });
      this.updateParams({ take: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams();
      this.loadItems();
    },

    onColumnFilter: _.debounce(function (params) {
      this.updateParams({
        search: params.columnFilters.role_name
      });
      this.loadItems();
    }, 300),
    loadItems() {
      this.isLoading = true;
      RoleService
        .getRoleList( this.$store.state.role.serverParams)
        .then(({ data: { data } }) => {
          this.rows = data.RoleCms
          this.totalRecords = data.totalData
        }).catch((e) => {
          if (e.response.status !== 404) {
            // console.log(e)
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message || "Terjadi Kesalahan",
            });
          } else {
            this.rows = []
          }
        }).finally(() => {
          this.isLoading = false;
        })
    },
    showAlertConfirm(id) {
      this.$swal
        .fire({
          title: "",
          text: "Apakah Data Akan Di Hapus?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ya",
        })
        .then(async (result) => {
          if (result.value) {
            await this.doDelete(id);
          }
        });
    },
    doDelete(id) {
      this.isLoading = true;
      RoleService
        .deleteRoleById(id)
        .then((response) => {
          this.$swal({
            title: "Data berhasil dihapus",
            showCloseButton: true,
          }).then(() => {
            this.loadItems();
          });
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message || "Terjadi Kesalahan",
          });
        }).finally(() => {
          this.isLoading = false;
        })
    }
  },
  computed: {
    edit: function () {
      if (this.permisions.find((r) => r == 'RoleCms Patch')) {
        return true
      }
      return false;
    },
    add: function () {
      if (this.permisions.find((r) => r == 'RoleCms Add')) {
        return true
      }
      return false;
    },
    deleteRole: function () {
      if (this.permisions.find((r) => r == 'RoleCms Delete')) {
        return true
      }
      return false;
    },
  },
  mounted() {
    this.loadItems();
  },
};
</script>
