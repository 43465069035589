<template>
  <div class="product-form">
    <FormPost form-type="add" @onValidateSuccess="doSave" />
  </div>
</template>
<script>
import FormPost from "./Form.vue";
export default {
  components: {
    FormPost,
  },

  methods: {
    async doSave(payload) {
      // console.log(payload)
    },
  },
};
</script>
