<template>
  <div>
    <h1 class="page-header"><span v-if="isEdit">Edit</span> Career Category</h1>
    <div class="row">
      <div class="col-md-6">
        <div class="panel">
          <div class="panel-body">
            <form @submit.prevent="doSave">
              <div class="row align-items-end">
                <div class="col">
                  <label class="form-label"><span v-if="!isEdit">Add New</span><span v-else>Edit</span> Career
                    Category</label>
                  <input type="text" class="form-control" placeholder="Add New Category Name" v-model="category" />
                  <span class="text-danger" v-if="$v.category.$error">Category Name is
                    required</span>
                  <br>
                </div>
                <div class="col-auto" v-if="add || edit">
                  <button type="submit" class="btn btn-outline-primary">
                    <span v-if="!isEdit && add">Add</span>
                    <span v-else>Save</span>
                  </button>
                  <br>
                  <label class="form-label"></label>
                </div>
                <div class="col-auto">
                  <button :disabled="!isEdit" type="submit" class="btn btn-outline-primary" @click.prevent="doCancel">
                    <span>Cancel</span>
                  </button>
                  <br>
                  <label class="form-label"></label>
                </div>
              </div>
            </form>
          </div>
        </div>
        <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="totalRecords"
          :isLoading.sync="isLoading" :pagination-options="{
            enabled: true,
            dropdownAllowAll: false,
          }" :columns="columns" :rows="rows" :sort-options="{
  enabled: false,
  initialSortBy: { field: 'category_name', type: 'asc' },
}" styleClass="vgt-table" slot="outsideBody">
          <template slot="column-filter" slot-scope="{ column, updateFilters }">
            <input ref="category_name" class="form-control" placeholder="Search category" v-if="column.filterOptions &&
              column.filterOptions.customFilter &&
              column.field === 'category_name'
              " @input="(value) => updateFilters(column, $refs.category_name.value)" />
          </template>
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'action'">
              <div class="row g-2 align-items-center justify-content-end">
                <div class="col-auto" v-if="edit">
                  <button type="button" class="btn btn-lg text-dark" @click="doEdit(props.row)" v-b-tooltip.hover
                    title="Edit">
                    <i class="fas fa-pencil"></i>
                  </button>
                </div>
                <div class="col-auto" v-if="deleteCareerCategory">
                  <button type="button" class="btn btn-lg text-dark" v-b-tooltip.hover title="Delete"
                    @click.prevent="showAlertConfirm(props.row.id)">
                    <i class="fas fa-trash"></i>
                  </button>
                </div>
              </div>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import CareerCategoryService from "@/service/careercategory.service";
import tokenService from "@/service/token.service";
import { required } from 'vuelidate/lib/validators'
export default {
  data() {
    return {
      category: "",
      isEdit: false,
      isLoading: false,
      columns: [
        {
          label: "Category Name",
          field: "category_name",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Action",
          field: "action",
          width: "200px",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
      ],
      rows: [],
      totalRecords: 0,
      serverParams: {
        search: '',
        take: 10,
        skip: 0,
      },
      errorCode: "",
      errorMessage: "",
      error: "",
      forbidenError: false,
      permisions: tokenService.getPermission()
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ skip: (params.currentPage - 1) * params.currentPerPage });
      this.loadItems();
    },
    onPerPageChange(params) {
      this.updateParams({ skip: 0 });
      this.updateParams({ take: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      // this.updateParams({
      //   sort: [
      //     {
      //       type: params[0].type,
      //       field: params[0].field,
      //     },
      //   ],
      // });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams({
        search: params.columnFilters.category_name
      });
      this.loadItems();
    },
    loadItems() {
      // this.rows = [
      //   {
      //     id: 1,
      //     categoryName: "Article",
      //   },
      //   {
      //     id: 2,
      //     categoryName: "News",
      //   },
      //   {
      //     id: 3,
      //     categoryName: "Event",
      //   },
      // ];

      this.isLoading = true;
      CareerCategoryService
        .getCareerCategoryList(this.serverParams)
        .then(({ data: { data } }) => {
          this.rows = data.CareerCategory
          this.totalRecords = data.totalData
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message.toString() || "Terjadi Kesalahan",
          });
        }).finally(() => {
          this.isLoading = false;
        })
    },
    doSave() {

      this.$v.category.$touch();

      if (!this.$v.category.$invalid) {
        const payload = {
          category_name: this.category,
        };

        if (this.isEdit) {
          this.editRequest(payload)
        } else {
          this.addRequest(payload)
        }
      }
    },

    doEdit(data) {
      this.isEdit = true
      this.category = data.category_name
      this.id = data.id
    },

    addRequest(req) {
      this.isLoading = true;
      CareerCategoryService
        .postCareerCategory(req)
        .then((response) => {
          this.$swal({
            title: "Data berhasil disimpan",
            showCloseButton: true,
          }).then(() => {
            this.loadItems();
          });
        }).catch((e) => {
          if (e.response?.status === 409) {
            this.$notify({
              type: "warn",
              title: "Error Message",
              text: "Data Sudah Ada",
            });
          } else {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message || "Terjadi Kesalahan",
            });
          }
        }).finally(() => {
          this.isLoading = false;
          this.doCancel()
        })
    },
    editRequest(req) {
      this.isLoading = true;
      CareerCategoryService
        .patchCareerCategory(this.id, req)
        .then((response) => {
          this.$swal({
            title: "Data berhasil disimpan",
            showCloseButton: true,
          }).then(() => {
            this.loadItems();
          });
        }).catch((e) => {
          if (e.response?.status === 409) {
            this.$notify({
              type: "warn",
              title: "Error Message",
              text: "Data Sudah Ada",
            });
          } else {
            this.$notify({
              type: "error",
              title: "Error Message " + e.response?.data?.error,
              text: e.response?.data?.message || "Terjadi Kesalahan",
            });
          }
        }).finally(() => {
          this.isLoading = false;
          this.doCancel()
        })
    },

    doCancel() {
      this.isEdit = false
      this.category = ''
      this.id = ''
      this.$v.$reset();
    },
    showAlertConfirm(id) {
      this.$swal
        .fire({
          title: "",
          text: "Apakah Data Akan Di Hapus?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ya",
        })
        .then(async (result) => {
          if (result.value) {
            await this.doDelete(id);
          }
        });
    },

    doDelete(id) {
      this.isLoading = true;
      CareerCategoryService
        .deleteCareerCategoryById(id)
        .then((response) => {
          this.$swal({
            title: "Data berhasil dihapus",
            showCloseButton: true,
          }).then(() => {
            this.loadItems();
          });
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message || "Terjadi Kesalahan",
          });
        }).finally(() => {
          this.isLoading = false;
          this.doCancel()
        })
    }

  },
  computed: {
    add: function () {
      if (this.permisions.find((r) => r == 'CareerCategory Add')) {
        return true
      }
      return false;
    },
    edit: function () {
      if (this.permisions.find((r) => r == 'CareerCategory Patch')) {
        return true
      }
      return false;
    },
    deleteCareerCategory: function () {
      if (this.permisions.find((r) => r == 'CareerCategory Delete')) {
        return true
      }
      return false;
    },
  },
  mounted() {
    this.loadItems();
  },
  validations: {
    category: { required },
  }
};
</script>
