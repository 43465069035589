<template>
  <div class="product-form">
    <FormUsers form-type="edit" :detail-data="getDetail" @onValidateSuccess="doSave" />
  </div>
</template>
<script>
import roleService from "@/service/role.service";
import FormUsers from "./Form.vue";
import RoleService from "@/service/role.service";
export default {
  data() {
    return {
      detail: {
        id: this.$route.params.id,
        title: ''
      },
    };
  },
  async mounted() {
    await this.loadItems(this.$route.params.id)
    // console.log(this.detail, 'detailData');
  },
  computed: {
    getDetail: function () {
      return this.detail
    }
  },
  components: {
    FormUsers,
  },

  methods: {
    async doSave(payload) {
      roleService.patchRole(this.$route.params.id, payload).then((rsp) => {
        this.$swal({
          title: "Data berhasil disimpan",
          showCloseButton: true,
        }).then(() => {
          this.$router.push({ path: '/users/manage/role' })
        });
      }).catch((e) => {
        if (e.response?.status === 409) {
          this.$notify({
            type: "warn",
            title: "Error Message",
            text: "Data Sudah Ada",
          });
        } else {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message.toString() || "Terjadi Kesalahan",
          });
        }
      }).finally(() =>
        this.onLoading = false)
    },
    loadItems(id) {
      RoleService.getRoleById(id)
        .then(async ({ data: { data } }) => {
          this.detail = data
        })
    },
  },
};
</script>
