<template>
  <form @submit.prevent="doSave" class="pb-5 mb-4">
    <div class="row">
      <div class="col-12">
        <h1 class="page-header">
          <button class="btn btn-primary" @click.prevent="$router.go(-1)">
            <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            <i class="fa fa-arrow-left" aria-hidden="true"></i>
            Back
          </button>
          {{ formTitle }} All Data
        </h1>
      </div>
      <div class="col-lg-9 col-md-8">
        <div class="mb-15px">
          <input type="text" class="form-control form-control-lg" placeholder="Title" v-model="form.title" />
          <span class="text-danger" v-if="$v.form.title.$error">Title is required</span>
        </div>
        <div class="mb-15px">
          <label class="form-label">Jenis Kelamin</label>
          <select class="form-select" v-model="form.gender">
            <option />
            <option v-for="(option, key) in [
              { title: 'Laki-laki', value: 'male' },
              { title: 'Perempuan', value: 'female' }
            ]" v-bind:value="option.value" :key="key" v-text="option.title" />
          </select>
          <span class="text-danger" v-if="$v.form.gender.$error">Gender is required</span>
        </div>
        <div class="mb-15px">
          <label class="form-label">Input Type</label>
          <select class="form-select" v-model="form.input_type" :clearable="false">
            <option />
            <option v-for="(option, key) in [
              { title: 'Berat Badan', value: 'weight' },
              { title: 'Panjang Badan', value: 'height' }
            ]" v-bind:value="option.value" :key="key" v-text="option.title" />
          </select>
          <span class="text-danger" v-if="$v.form.input_type.$error">Gender is required</span>
        </div>
        <panel variant="inverse" v-if="form.input_type == 'weight'"
          :title="form.input_type == 'weight' ? 'Berat Badan (kg)' : 'Panjang Badan (cm)'" :noButton="true">
          <panel v-for="(item, indexContent) in  $v.form.babyGrowthWeight.$each.$iter " :key="parseInt(indexContent)"
            :index="parseInt(indexContent)" :title="`Standar nilai (SD)`" variant="grey" :is-collapse="true"
            :checkbox-hidden="false" :has-remove="true" :show-collapse="true"
            :checkbox-name="`weight-${parseInt(indexContent)}`" @onRemove="removeWeightItem(parseInt(indexContent))">
            <div class="row">
              <div class="col-md-4">
                <div class="mb-15px">
                  <label class="form-label">Usia (Bulan)</label>
                  <input type="text" class="form-control" v-model="item.$model.age_in_month" v-on:keypress="isNumber" />
                  <span class="text-danger" v-if="item.age_in_month.$error">Usia is required</span>
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-15px">
                  <label class="form-label">Ambang Batas</label>
                  <!-- <v-select v-model="item.$model.threshold_id" :options="optionsAmbang" :clearable="false" label="name" /> -->
                  <select class="form-select" v-model="item.$model.threshold_id">
                    <option v-for="(option, key) in optionsAmbang" v-bind:value="option.id" :key="key"
                      v-text="option.name" />
                  </select>
                  <span class="text-danger" v-if="item.threshold_id.$error">Ambang Usia is required</span>
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-15px">
                  <label class="form-label">{{ form.input_type == 'weight' ? 'Nilai BB (kg)' : 'Nilai PB (cm)' }} </label>
                  <input type="number" v-model="item.$model.heigth_or_weight_value" class="form-control" min="0"
                    step="any">
                  <span class="text-danger" v-if="item.heigth_or_weight_value.$error">
                    {{ form.input_type == 'weight' ? 'Nilai BB (kg)' : 'Nilai PB(cm)' }} is required</span>
                </div>
              </div>
            </div>
          </panel>
          <button @click="addWeightItem()" class="btn btn-outline-primary w-100 d-block text-start" type="button">
            <i class="fa-solid fa-plus"></i> Add Another
          </button>
        </panel>

        <panel variant="inverse" v-else :title="form.input_type == 'weight' ? 'Berat Badan (kg)' : 'Panjang Badan (cm)'"
          :noButton="true">
          <panel v-for="(item, indexContent) in  $v.form.babyGrowthHeight.$each.$iter " :key="parseInt(indexContent)"
            :index="parseInt(indexContent)" :title="`Standar nilai (SD)`" variant="grey" :is-collapse="true"
            :checkbox-hidden="false" :has-remove="true" :show-collapse="true"
            :checkbox-name="`weight-${parseInt(indexContent)}`" @onRemove="removeHeightItem(parseInt(indexContent))">
            <div class="row">
              <div class="col-md-4">
                <div class="mb-15px">
                  <label class="form-label">Usia (Bulan)</label>
                  <input type="text" class="form-control" v-model="item.$model.age_in_month" v-on:keypress="isNumber" />
                  <span class="text-danger" v-if="item.age_in_month.$error">Usia is required</span>
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-15px">
                  <label class="form-label">Ambang Batas</label>
                  <!-- <v-select v-model="item.$model.threshold_id" :options="optionsAmbang" :clearable="false" label="name" /> -->
                  <select class="form-select" v-model="item.$model.threshold_id">
                    <option v-for="(option, key) in optionsAmbang" v-bind:value="option.id" :key="key"
                      v-text="option.name" />
                  </select>
                  <span class="text-danger" v-if="item.threshold_id.$error">Ambang Usia is required</span>
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-15px">
                  <label class="form-label">{{ form.input_type == 'weight' ? 'Nilai BB (kg)' : 'Nilai PB (cm)' }} </label>
                  <input type="number" v-model="item.$model.heigth_or_weight_value" class="form-control" min="0"
                    step="any">
                  <span class="text-danger" v-if="item.heigth_or_weight_value.$error">
                    {{ form.input_type == 'weight' ? 'Nilai BB (kg)' : 'Nilai PB(cm)' }} is required</span>
                </div>
              </div>
            </div>
          </panel>
          <button @click="addHeightItem()" class="btn btn-outline-primary w-100 d-block text-start" type="button">
            <i class="fa-solid fa-plus"></i> Add Another
          </button>
        </panel>
      </div>
      <!-- <div class="col-lg-3 col-md-4">
        <div class="panel panel-sticky">
          <div class="panel-body">
            <div class="mb-15px">
              <p class="fw-bolder mb-1">Published Date</p>
              <p class="mb-0">22 December 2022</p>
            </div>
            <div class="mb-15px">
              <p class="fw-bolder mb-1">Last Modified Date</p>
              <p class="mb-0">22 December 2022</p>
            </div>
            <div class="mb-15px">
              <p class="fw-bolder mb-1">Author</p>
              <p class="mb-0">Wanda Vu</p>
            </div>
          </div>
        </div>
      </div> -->
    </div>
    <div class="form-action">
      <div class="row justify-content-end">
        <div class="col-auto">
          <button type="submit" class="btn btn-primary">
            <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            Save
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { defaultToolbar } from "../../../config/Helpers";
import UploadImage from "../../../components/upload/Image.vue";
import ambangbatascategoryService from "@/service/ambangbatascategory.service";
import statusgiziCategoryService from "@/service/gizistatuscategory.service";
import { required } from 'vuelidate/lib/validators'

export default {
  components: {
    UploadImage,
  },
  props: {
    formType: {
      type: String,
      default: "add",
    },
    detailData: {
      type: Object,
      default: null,
    },
    onLoading: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.loadAmbang()
  },
  data() {
    const detail = this.detailData;
    const defaultToolbarEditor = defaultToolbar;

    return {
      customToolbar: defaultToolbarEditor,
      optionsAmbang: [],
      form: {
        title: detail ? detail.title : null,
        gender: 'Perempuan',
        type: 'Berat Badan',
        babyGrowthIndex: [
          {
            id: '',
            age_in_month: '',
            threshold_id: '',
            heigth_or_weight_value: ''
          },
          {
            id: '',
            age_in_month: '',
            threshold_id: '',
            heigth_or_weight_value: ''
          }
        ],
        babyGrowthWeight: [
          {
            id: '',
            age_in_month: '',
            threshold_id: '',
            heigth_or_weight_value: ''
          },
          {
            id: '',
            age_in_month: '',
            threshold_id: '',
            heigth_or_weight_value: ''
          }
        ],
        babyGrowthHeight: [
          {
            id: '',
            age_in_month: '',
            threshold_id: '',
            heigth_or_weight_value: ''
          },
          {
            id: '',
            age_in_month: '',
            threshold_id: '',
            heigth_or_weight_value: ''
          }
        ],
      },
    };
  },
  computed: {
    formTitle() {
      return this.formType === "add" ? "Add New" : "Edit";
    },
  },
  watch: {
    detailData(newVal) {
      this.form = newVal;
    },
  },
  methods: {
    addWeightItem() {
      this.form.babyGrowthWeight.push({
        usia: '',
        ambangBatas: '',
        bb: ''
      });
    },
    removeWeightItem(index) {
      this.form.babyGrowthWeight.splice(index, 1);
    },
    addHeightItem() {
      this.form.babyGrowthHeight.push({
        usia: '',
        ambangBatas: '',
        pb: ''
      });
    },
    removeHeightItem(index) {
      this.form.babyGrowthHeight.splice(index, 1);
    },
    doSave() {

      this.$v.form.$touch();

      if (!this.$v.form.$invalid) {
        const payload = {
          form: this.form,
        };

        this.$emit("onValidateSuccess", payload);
      } else {
        this.$notify({
          type: "warn",
          title: "Form",
          text: "Form Belum Lengkap",
        });
      }
    },
    isNumber(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[0-9]+$/.test(char)) return true; // Match with regex 
      else e.preventDefault(); // If not match, don't add to input text
    },
    loadAmbang() {
      this.isLoading = true;
      ambangbatascategoryService
        .getAmbangBatasCategoryList(this.serverParams)
        .then(({ data: { data } }) => {
          this.optionsAmbang = data.ThresholdCategory
        }).catch((e) => {
          if (e.response.status !== 404) {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message || "Terjadi Kesalahan",
            });
          } else {
            this.rows = []
          }
        }).finally(() => {
          this.isLoading = false;
        })
    },
  },
  validations: {
    form: {
      title: { required },
      gender: { required },
      input_type: { required },
      babyGrowthWeight: {
        $each: {
          age_in_month: {},
          threshold_id: {},
          heigth_or_weight_value: {}
        }
      },
      babyGrowthHeight: {
        $each: {
          age_in_month: {},
          threshold_id: {},
          heigth_or_weight_value: {}
        }
      },
    }
  }
};
</script>
