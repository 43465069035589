import UserService from "../service/user.service";

export default {
  namespaced: true,
  state: {
    serverParams: {
      search: '',
      take: 10,
      skip: 0,
      sort: 'desc'
    },
    count: 0,
    data: {}
  },
  actions: {
    async getListUser(_ctx, serverParams) {
      return UserService.getListUser(serverParams).then((result) => {
        return result
      }).catch((err) => {
        return err
      });
    },
    async postUser(_ctx, serverParams) {
      return UserService.postUser(serverParams).then((result) => {
        return result
      }).catch((err) => {
        return err
      });
    },
    async getUserById(_ctx, serverParams) {
      return UserService.getUserById(serverParams).then((result) => {
        return result
      }).catch((err) => {
        return err
      });
    },
    async deleteUserById(_ctx, serverParams) {
      return UserService.deleteUserById(serverParams).then((result) => {
        return result
      }).catch((err) => {
        return err
      });
    },
    async updateUserById(_ctx, serverParams) {
      const { id, ...payload } = serverParams
      return UserService.putUser(id, payload).then((result) => {
        return result
      }).catch((err) => {
        return err
      });
    },
  },
  getters: {
    data: state => state.data,
  },
  mutations: {
    SET_DATA(state, data) {
      state.data = data;
    },
  }
};
