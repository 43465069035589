<template>
  <div class="product-form">
    <FormPost form-type="edit" :detail-data="form" @onValidateSuccess="doSave" :on-loading="onLoading" />
  </div>
</template>
<script>
import FormPost from "./Form.vue";
import BabyNameService from "@/service/babyname.service";
import base64Converter from "@/pages/Helper/base64";
import slugify from "@/pages/Helper/slug";
import moment from "moment";
export default {
  data() {
    return {
      onLoading: false,
      form: {
        id: this.$route.params.id,
        title: "",
      },
    };
  },
  components: {
    FormPost,
  },
  mounted() {
    this.loadItems(this.$route.params.id)
  },
  methods: {
    async doSave(payload) {
      // Submit here
      this.onLoading = true

      let { form } = payload
      if (form) {
        payload = {
          gender: form.gender ? form.gender : null,
          name: form.title ? form.title : null,
          theme: form.theme ? form.theme : null,
          region: form.region ? form.region : null,
          syllables: form.syllables ? form.syllables : null,
          definition: form.definition ? form.definition : null,
        }
        // console.log(payload);
        BabyNameService.patchBabyName(this.$route.params.id, payload).then((rsp) => {
          this.$swal({
            title: "Data berhasil disimpan",
            showCloseButton: true,
          }).then(() => {
            this.$router.push({ path: '/baby-name-generator/list' })
          });
        }).catch((e) => {
          if (e.response?.status === 409) {
            this.$notify({
              type: "warn",
              title: "Error Message",
              text: "Data Sudah Ada",
            });
          } else {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message.toString() || "Terjadi Kesalahan",
            });
          }
        }).finally(() =>
          this.onLoading = false)
      }
    },
    loadItems(id) {
      BabyNameService.getBabyNameById(id)
        .then(async ({ data: { data } }) => {
          this.form = {
            title: data.name,
            ...data
          }
        }).catch((e) => {
          // console.log(e) 
        })
    },
  },
};
</script>
