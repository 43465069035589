<template>
  <div class="product-form">
    <FormSocmed form-type="edit" :detail-data="detail" @onValidateSuccess="doSave" />
  </div>
</template>
<script>
import FormSocmed from "./Form.vue";
import SiteSettingService from "@/service/sitesetting.service";
import base64Converter from "@/pages/Helper/base64";
export default {
  data() {
    return {
      detail: {
        form: {},
        id: {}
      },
    };
  },
  components: {
    FormSocmed,
  },
  mounted() {
    // this.loadItems(this.$route.params.id)
  },
  methods: {
    async doSave(payload) {
      // Submit here
      let { form, id } = payload
      let { imagesSrc, ...newForm } = form
      let arrReq = []
      for (const v in newForm) {
        if (id[v]) {
          arrReq.push(this.editRequest(id[v], {
            caller_param: v + '_' + newForm.name.toLowerCase(),
            title: v + '_' + newForm.name.toLowerCase(),
            description: v + '_' + newForm.name.toLowerCase(),
            default_value: (v == 'images') ? await base64Converter(newForm[v][0]) : newForm[v],
            attribute_value: "",
            type: (v == 'images') ? "image" : "text",
            grup: newForm.name.toLowerCase()
          }))
        } else {
          arrReq.push(this.addRequest({
            caller_param: v + '_' + newForm.name.toLowerCase(),
            title: v + '_' + newForm.name.toLowerCase(),
            description: v + '_' + newForm.name.toLowerCase(),
            default_value: (v == 'images') ? await base64Converter(newForm[v][0]) : newForm[v],
            attribute_value: "",
            type: (v == 'images') ? "image" : "text",
            grup: newForm.name.toLowerCase()
          }))
        }
      }

      arrReq.push(this.editRequest(this.$route.params.id, {
        caller_param: newForm.name.toLowerCase(),
        title: newForm.name.toLowerCase(),
        description: newForm.name.toLowerCase(),
        default_value: newForm.name,
        attribute_value: "",
        type: "text",
        grup: "social_media"
      }))

      Promise.all(arrReq)
        .then((r) => {
          this.$swal({
            title: "Data berhasil disimpan",
            showCloseButton: true,
          }).then(() => {
            // this.$router.push({ path: '/setting/social-media' })
          });
        }).catch((e) => {
          if (e.response?.status === 409) {
            this.$notify({
              type: "warn",
              title: "Error Message",
              text: "Data Sudah Ada",
            });
          } else {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message || "Terjadi Kesalahan",
            });
          }
        }).finally(() => {
          this.$router.push({ path: '/setting/social-media' })
        })
    },

    addRequest(req) {
      return SiteSettingService
        .postSiteSetting(req)
        .then((response) => {
          return Promise.resolve({ ...response })
        }).catch((e) => {
          return Promise.reject({ ...e })
        })
    },
    editRequest(id, req) {
      return SiteSettingService
        .patchSiteSetting(id, req)
        .then((response) => {
          return Promise.resolve({ ...response })
        }).catch((e) => {
          return Promise.reject({ ...e })
        })
    },
  },
};
</script>
