<template>
  <div class="product-form">
    <FormPages form-type="add" @onValidateSuccess="doSave" :on-loading="onLoading" />
  </div>
</template>
<script>
import FormPages from './Form.vue'
import PageService from "@/service/page.service";
import base64Converter from "../../pages/Helper/base64";

export default {
  components: {
    FormPages,
  },
  data() {
    return {
      onLoading: false
    }
  },
  methods: {
    async doSave(payload) {
      // Submit here
      this.onLoading = true
      let req = {
        title: payload.title,
        slug: payload.slug,
        page_template: payload.template,
        description: payload.content,
        desktop_image: (payload.images.desktop && payload.images.desktop.length > 0) ?
          await base64Converter(payload.images.desktop[0]) : null,
        mobile_image: (payload.images.mobile && payload.images.mobile.length > 0) ?
          await base64Converter(payload.images.mobile[0]) : null,
        main_image_alt_text: payload.images.alt ? payload.images.alt : null,
        link_youtube: payload.youtube ? payload.youtube : null,
        status: payload.status,
        alt_desktop_image: payload.images.alt_desktop_image ? payload.images.alt_desktop_image : null,
        alt_mobile_image: payload.images.alt_mobile_image ? payload.images.alt_mobile_image : null,
        seoInformation: {
          meta_title: payload.meta.title,
          meta_description: payload.meta.description,
          meta_keyword: payload.meta.keywords.toString(),
          image: (payload.meta.image && payload.meta.image.length > 0) ?
            await base64Converter(payload.meta.image[0]) : null,
          alt_image: payload.meta.alt_image ? payload.meta.alt_image : null,
        }
      }

      PageService.postPage(req).then((rsp) => {
        this.$swal({
          title: "Data berhasil disimpan",
          showCloseButton: true,
        }).then(() => {
          this.$router.push({ path: '/pages/list' })
        });
      }).catch((e) => {
        if (e.response?.status === 409) {
          this.$notify({
            type: "warn",
            title: "Error Message",
            text: "Data Sudah Ada",
          });
        } else {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message || "Terjadi Kesalahan",
          });
        }
      }).finally(() =>
        this.onLoading = false)
    }
  }
}
</script>
