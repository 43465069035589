import Vue from "vue";
import VueX from "vuex";

import auth from "./auth.module";
import menu from "./menu.module";
import post from "./post.module";
import customer from "./customer.module";
import product from "./product.module";
import productcategory from "./productcategory.module";
import parentproductcategory from "./parentproductcategory.module";
import ecommerce from "./ecommerce.module";
import page from "./page.module";
import contactus from "./contactus.module";
import promo from "./promo.module";
import banner from "./banner.module";
import claimpoint from "./claimtpoint.module";
import reward from "./reward.module";
import redeem from "./redeem.module";
import career from "./career.module";
import candidate from "./candidate.module";
import questionsurvey from "./questionsurvey.module";
import survey from "./survey.module";
import reseller from "./reseller.module";
import resellersupervisor from "./resellersupervisor.module";
import babygrowth from "./babygrowth.module";
import statusgizi from "./statusgizi.module";
import faq from "./faq.module";
import nutriexpert from "./nutriexpert.module";
import babynamegenerator from "./babynamegenerator.module";
import user from "./user.module";
import role from "./role.module";
import file from "./file.module";



Vue.use(VueX);

export const store = new VueX.Store({
  // state: {
  //     count: 0
  // },
  // mutations: {
  //     increment(context) {
  //         context.commit('increment')
  //     }
  // },
  // actions: {
  //     // getListSio(_ctx, serverParams) {
  //     //     console.log(serverParams);
  //     //     return sioService.getSioQuestionList(serverParams).then((result) => {
  //     //         return result
  //     //     }).catch((err) => {
  //     //         return err
  //     //     });
  //     //     // return SioService.getSioQuestionList({ serverParams: serverParams }).then(
  //     //     //     (response) => {
  //     //     //         return Promise.resolve(response);
  //     //     //     }
  //     //     // );
  //     // },
  //     increment(context) {
  //         return this.state.count
  //     }
  // },

  // state: {
  //     appOptions: {
  //       appSidebarMinified: false,
  //       appSidebarNone: false,
  //       appSidebarEnd: false,
  //       appSidebarTwo: false,
  //       appSidebarWide: false,
  //       appSidebarLight: false,
  //       appSidebarTransparent: false,
  //       appSidebarMobileToggled: false,
  //       appSidebarEndMobileToggled: false,
  //       appSidebarEndToggled: false,
  //       appSidebarEndCollapsed: false,
  //       appSidebarSearch: false,
  //       appContentFullHeight: false,
  //       appContentClass: false,
  //       appHeaderLanguageBar: false,
  //       appHeaderInverse: false,
  //       appHeaderMegaMenu: false,
  //       appHeaderMegaMenuMobileToggled: false,
  //       appTopMenu: false,
  //       appTopMenuMobileToggled: false,
  //       appEmpty: false,
  //       appBodyScrollTop: 0,
  //       appNavbarNotification: null,
  //       // Deprecated, use $store.state.auth.login instead
  //       //appNavbarUser: false
  //       contentLoading: false
  //     },
  // },
  modules: {
    menu,
    auth,
    post,
    customer,
    product,
    productcategory,
    parentproductcategory,
    ecommerce,
    page,
    contactus,
    promo,
    banner,
    claimpoint,
    reward,
    redeem,
    career,
    candidate,
    questionsurvey,
    survey,
    reseller,
    resellersupervisor,
    babygrowth,
    statusgizi,
    faq,
    nutriexpert,
    babynamegenerator,
    user,
    role,
    file
  },
})
