export default {
  namespaced: true,
  state: {
    serverParams: {
      search: '',
      take: 10,
      skip: 0,
      sort: 'desc'
    }
  },
  actions: {},
};
