<template>
  <div class="product-form">
    <FormPost form-type="add" @onValidateSuccess="doSave" />
  </div>
</template>
<script>
import surveyService from "@/service/survey.service";
import FormPost from "./Form.vue";
import base64Converter from "@/pages/Helper/base64";
import TokenService from "@/service/token.service";
export default {
  components: {
    FormPost,
  },

  methods: {
    async doSave(payload) {
      let { status } = payload
      let { answers, product, category, answers_type, type, question, order, is_other } = payload.survey
      let options = []
      if (answers.length > 0) {
        for (const [k, v] of Object.entries(answers)) {
          options.push({
            option: v.option ? v.option : '',
            option_image: v.icon ? await base64Converter(v.icon[0]) : null,
            is_disabled: v.disabled ? v.disabled : false
          })
        }
      }

      let request = {
        question: question ? question : null,
        related_product_category_id: product ? product : 0,
        type_of_question: type ? type : null,
        type_of_answer: answers_type ? answers_type : null,
        question_section: category ? category : null,
        order: order ? order : null,
        is_other: is_other ? is_other : false,
        options: options,
        createdBy: (TokenService.getUser()).id,
        status
      }

      surveyService.postSurvey(request).then((rsp) => {
        this.$swal({
          title: "Data berhasil disimpan",
          showCloseButton: true,
        }).then(() => {
          this.$router.push({ path: '/survey/list' })
        });
      }).catch((e) => {
        if (e.response?.status === 409) {
          this.$notify({
            type: "warn",
            title: "Error Message",
            text: "Data Sudah Ada",
          });
        } else {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message || "Terjadi Kesalahan",
          });
        }
      }).finally(() =>
        this.onLoading = false)
    },
  },
};
</script>
