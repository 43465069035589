<template>
  <div class="product-form">
    <FormPost form-type="edit" :detail-data="form" @onValidateSuccess="doSave" />
  </div>
</template>
<script>
import FormPost from "./Form.vue";
import nutriexpertService from "@/service/nutriexpert.service";
export default {
  data() {
    return {
      form: {
        id: this.$route.params.id,
        title: "",
      },
    };
  },
  components: {
    FormPost,
  },
  mounted() {
    this.loadItems(this.$route.params.id)
  },
  methods: {
    async doSave(payload) {
      // Submit here
      this.onLoading = true
      let { form } = payload
      let req = {
        title: form.title ? form.title : null,
        email: form.email ? form.email : null,
        phone_number: form.phone_number ? '+62' + form.phone_number : null,
        answer: form.answer ? form.answer : null,
        nama: form.nama ? form.nama : null,
        category_id: form.Category ? form.Category.id : 0
      }

      nutriexpertService.patchNutriExpert(this.$route.params.id, req).then((rsp) => {
        this.$swal({
          title: "Data berhasil disimpan",
          showCloseButton: true,
        }).then(() => {
          this.$router.push({ path: '/pages/nutri-expert' })
        });
      }).catch((e) => {
        if (e.response?.status === 409) {
          this.$notify({
            type: "warn",
            title: "Error Message",
            text: "Data Sudah Ada",
          });
        } else {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message || "Terjadi Kesalahan",
          });
        }
      }).finally(() =>
        this.onLoading = false)
    },

    loadItems(id) {
      nutriexpertService.getNutriExpertById(id)
        .then(async ({ data: { data } }) => {
          this.form = {
            ...data,
            phone_number: data.phone_number ? data.phone_number.replace('+62', '') : null,
          }
        })
    },
  },
};
</script>
