<template>
  <form @submit.prevent="doSave" class="pb-5 mb-4">
    <div class="row">
      <div class="col-12">
        <h1 class="page-header">
          <button class="btn btn-primary" @click.prevent="$router.go(-1)">
            <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            <i class="fa fa-arrow-left" aria-hidden="true"></i>
            Back
          </button>
          {{ formTitle }} Baby Name Generator List
        </h1>
      </div>
      <div class="col-lg-9 col-md-8">
        <div class="mb-15px">
          <input type="text" class="form-control form-control-lg" placeholder="AUDRIC" v-model="form.title" />
          <span class="text-danger" v-if="$v.form.title.$error">Title is required</span>
        </div>
        <div class="panel">
          <div class="panel-body">
            <div class="row">
              <div class="col-md-3">
                <div class="mb-15px">
                  <label class="form-label">Jenis Kelamin</label>
                  <select class="form-select" v-model="form.gender">
                    <option />
                    <option v-for="(option, key) in [
                      { title: 'Laki-laki', value: 'male' },
                      { title: 'Perempuan', value: 'female' }
                    ]" v-bind:value="option.value" :key="key" v-text="option.title" />
                  </select>
                  <span class="text-danger" v-if="$v.form.gender.$error">Jenis Kelamin is required</span>
                </div>
              </div>
              <div class="col-md-3">
                <div class="mb-15px">
                  <label class="form-label">Tema</label>
                  <input type="text" class="form-control" placeholder="Tema" v-model="form.theme">
                  <span class="text-danger" v-if="$v.form.theme.$error">Tema is required</span>
                </div>
              </div>
              <div class="col-md-3">
                <div class="mb-15px">
                  <label class="form-label">Negara</label>
                  <input type="text" class="form-control" placeholder="Negara" v-model="form.region">
                  <span class="text-danger" v-if="$v.form.region.$error">Negara is required</span>
                </div>
              </div>
              <div class="col-md-3">
                <div class="mb-15px">
                  <label class="form-label">Suku Kata</label>
                  <input type="text" class="form-control" placeholder="Suku Kata" v-model="form.syllables"
                    v-on:keypress="isNumber">
                  <span class="text-danger" v-if="$v.form.syllables.$error">Suku Kata is required</span>
                </div>
              </div>
              <div class="col-md-12">
                <label class="form-label">Arti Nama</label>
                <ckeditor v-model="form.definition" />
                <span class="text-danger" v-if="$v.form.definition.$error">Arti Nama is required</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-action">
      <div class="row justify-content-end">
        <div class="col-auto">
          <button type="submit" class="btn btn-primary">
            <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            Save</button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { defaultToolbar } from "../../config/Helpers";
import UploadImage from "../../components/upload/Image.vue";
import { required } from 'vuelidate/lib/validators'

export default {
  components: {
    UploadImage,
  },
  props: {
    formType: {
      type: String,
      default: "add",
    },
    detailData: {
      type: Object,
      default: null,
    },
    onLoading: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    // console.log(this.detailData);
  },
  data() {
    const detail = this.detailData;
    const defaultToolbarEditor = defaultToolbar;

    return {
      options: {
        format: "DD/MM/YYYY",
        useCurrent: true,
      },
      customToolbar: defaultToolbarEditor,
      form: {
        title: detail ? detail.title : null,
        gender: detail ? detail.gender : null,
        theme: detail ? detail.theme : null,
        region: detail ? detail.region : null,
        syllables: detail ? detail.syllables : null,
        definition: detail ? detail.definition : null,
      },
    };
  },
  computed: {
    formTitle() {
      return this.formType === "add" ? "Add New" : "Edit";
    },
  },
  watch: {
    detailData(newVal) {
      this.form = newVal;
    },
  },
  methods: {
    doSave() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const payload = {
          form: this.form,
        };
        console.log(payload);

        this.$emit("onValidateSuccess", payload);
      } else {
        this.$notify({
          type: "warn",
          title: "Form",
          text: "Form Belum Lengkap",
        });
      }
    },
    isNumber(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[0-9]+$/.test(char)) return true; // Match with regex 
      else e.preventDefault(); // If not match, don't add to input text
    }
  },
  validations: {
    form: {
      title: { required },
      gender: { required },
      theme: { required },
      region: { required },
      syllables: { required },
      definition: { required },
    },
  }
};
</script>
