<template>
  <div>
    <div class="row align-items-center justify-content-between mb-4">
      <div class="col-auto">
        <!-- BEGIN page-header -->
        <h1 class="page-header">All {{ postType }}</h1>
        <!-- END page-header -->
      </div>
      <div class="col-auto mb-3" v-if="(postType == 'Articles' && addArticle) || (postType == 'Promo' && addPromo)">
        <router-link to="/posts/promo/add" v-if="postType === 'Promo'">
          <a class="btn btn-outline-primary"> Add New {{ postType }}</a>
        </router-link>
        <router-link to="/posts/add" v-else>
          <a class="btn btn-outline-primary"> Add New {{ postType }}</a>
        </router-link>
      </div>
    </div>
    <ul class="nav nav-tabs nav-tabs--wp">
      <li class="nav-item" v-if="(addArticle || editArticle || duplicateArticle || deletePostArticleArticle)">
        <router-link to="/posts/list/data" class="nav-link" active-class="active" exact>
          Articles
        </router-link>
      </li>
      <li class="nav-item" v-if="(addPromo || editPromo || duplicatePromo || deletePromo)">
        <router-link to="/posts/list/promo" class="nav-link" active-class="active" exact>
          Promo
        </router-link>
      </li>
    </ul>
    <router-view></router-view>
  </div>
</template>

<script>

import tokenService from "@/service/token.service";
export default {
  data() {
    return {
      permisions: tokenService.getPermission()
    }
  },
  computed: {
    postType() {
      return this.$route.path.includes('promo') ? 'Promo' : 'Articles'
    },
    addArticle: function () {
      if (this.permisions.find((r) => r == 'PostArticle Add')) {
        return true
      }
      return false;
    },
    duplicateArticle: function () {
      if (this.permisions.find((r) => r == 'PostArticle Duplicate')) {
        return true
      }
      return false;
    },
    editArticle: function () {
      if (this.permisions.find((r) => r == 'PostArticle Patch')) {
        return true
      }
      return false;
    },
    deletePostArticleArticle: function () {
      if (this.permisions.find((r) => r == 'PostArticle Delete')) {
        return true
      }
      return false;
    },
    addPromo: function () {
      if (this.permisions.find((r) => r == 'Promo Add')) {
        return true
      }
      return false;
    },
    duplicatePromo: function () {
      if (this.permisions.find((r) => r == 'Promo Duplicate')) {
        return true
      }
      return false;
    },
    editPromo: function () {
      if (this.permisions.find((r) => r == 'Promo Patch')) {
        return true
      }
      return false;
    },
    deletePromo: function () {
      if (this.permisions.find((r) => r == 'Promo Delete')) {
        return true
      }
      return false;
    },
  },
}
</script>
