<template>
  <div class="product-form">
    <FormPost form-type="add" @onValidateSuccess="doSave" :on-loading="onLoading" />
  </div>
</template>
<script>
import FormPost from './Form.vue'
import PromoService from "@/service/promo.service";
import TokenService from "@/service/token.service";
import moment from "moment";
import base64Converter from "@/pages/Helper/base64";

export default {
  data() {
    return {
      onLoading: false
    }
  },
  components: {
    FormPost,
  },

  methods: {
    async doSave(payload) {

      let { promo, status } = payload
      if (promo) {
        let Ecommerce = []
        if (promo.ecommerce.length > 0) {
          for (const [k, v] of Object.entries(promo.ecommerce)) {
            if (v.ecommerce.id || v.url) {
              Ecommerce.push({
                ecommerce_id: v.ecommerce.id,
                url_market_place: v.url ? v.url : null
              })
            }
          }
        }

        payload = {
          user_id: (TokenService.getUser()).id,
          promo_title: promo.title,
          slug: promo.slug,
          ecommerce: Ecommerce,
          promo_category_id: promo.category.id,
          start_period: this.dateFormat(promo.date.start),
          end_period: this.dateFormat(promo.date.end),
          description: promo.content,
          link_youtube: promo.youtube ? promo.youtube : null,
          desktop_image: (promo.images.desktop && promo.images.desktop.length > 0) ?
            await base64Converter(promo.images.desktop[0]) : null,
          mobile_image: (promo.images.mobile && promo.images.mobile.length > 0) ?
            await base64Converter(promo.images.mobile[0]) : null,
          image_thumbnail: (promo.images.thumbnail && promo.images.thumbnail.length > 0) ?
            await base64Converter(promo.images.thumbnail[0]) : null,
          alt_image_thumbnail: promo.images.alt_thumbnail ? promo.images.alt_thumbnail : null,
          main_image_alt_text: promo.images.alt ? promo.images.alt : null,
          alt_desktop_image: promo.images.alt_desktop_image ? promo.images.alt_desktop_image : null,
          alt_mobile_image: promo.images.alt_mobile_image ? promo.images.alt_mobile_image : null,
          img_homepage: (promo.images.img_homepage && promo.images.img_homepage.length > 0) ?
            await base64Converter(promo.images.img_homepage[0]) : null,
          "seoInformation": {
            meta_title: promo.meta.title,
            meta_description: promo.meta.description,
            meta_keyword: promo.meta.keywords.toString(),
            image: (promo.meta.image && promo.meta.image.length > 0) ?
              await base64Converter(promo.meta.image[0]) : null,
            alt_image: promo.meta.alt_image ? promo.meta.alt_image : null,
          },
          status
        }

        PromoService.postPromo(payload).then((rsp) => {
          this.$swal({
            title: "Data berhasil disimpan",
            showCloseButton: true,
          }).then(() => {
            this.$router.push({ path: '/posts/list/promo' })
          });
        }).catch((e) => {
          if (e.response?.status === 409) {
            this.$notify({
              type: "warn",
              title: "Error Message",
              text: "Data Sudah Ada",
            });
          } else {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message.toString() || "Terjadi Kesalahan",
            });
          }
        }).finally(() =>
          this.onLoading = false)
      }
    },
    dateFormat(date) {
      return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD')
    }
  }
}
</script>
