import apim from "./apim";

class FileService {
  /** @type {ApiAxiosInstance} */
  apim;

  constructor() {
    this.apim = apim.create('file-upload')
  }

  postFileUpload(serverParams) {
    return this.apim.post("/", serverParams);
  }
}

export default new FileService();
