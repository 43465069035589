import RoleService from "../service/role.service";

export default {
  namespaced: true,
  state: {
    serverParams: {
      search: '',
      take: 10,
      skip: 0,
      sort: 'desc'
    },
    count: 0,
    data: {}
  },
  actions: {
    async getListRole(_ctx, serverParams) {
      return RoleService.getListRole(serverParams).then((result) => {
        return result
      }).catch((err) => {
        return err
      });
    },
    async postRole(_ctx, serverParams) {
      return RoleService.postRole(serverParams).then((result) => {
        return result
      }).catch((err) => {
        return err
      });
    },
    async getRoleById(_ctx, serverParams) {
      return RoleService.getRoleById(serverParams).then((result) => {
        return result
      }).catch((err) => {
        return err
      });
    },
    async deleteRoleById(_ctx, serverParams) {
      return RoleService.deleteRoleById(serverParams).then((result) => {
        return result
      }).catch((err) => {
        return err
      });
    },
    async updateRoleById(_ctx, serverParams) {
      const { id, ...payload } = serverParams
      return RoleService.putRole(id, payload).then((result) => {
        return result
      }).catch((err) => {
        return err
      });
    },
  },
  getters: {
    data: state => state.data,
  },
  mutations: {
    SET_DATA(state, data) {
      state.data = data;
    },
  }
};
