import PageService from "../service/page.service";
export default {
  namespaced: true,
  state: {
    serverParams: {
      search: '',
      take: 10,
      skip: 0,
      sort: 'desc'
    }
  },
  actions: {
    async getPageLists(_ctx, serverParams) {
      return PageService.getPageLists(serverParams)
        .then((result) => {
          return result;
        })
        .catch((err) => {
          return err;
        });
    },
    createPage(_ctx, page) {
      return PageService.createPage(page).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getSinglePage(_ctx, id) {
      return PageService.getSinglePage(id).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    deletePage(_ctx, id) {
      return PageService.deletePage(id);
    },
    updatePage(_ctx, data) {
      return PageService.updatePage(data.id, data.body);
    },
  },
};
