<template>
  <div>
    <h1 class="page-header">Free Sample Submission</h1>
    <div class="col-auto mb-15px" v-if="exportExcel">
      <a class="btn btn-outline-primary" @click.prevent="downloadExcel">
        <i class="fa-solid fa-file-import"></i>
        Export
      </a>
    </div>
    <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="totalRecords"
      :isLoading.sync="isLoading" :pagination-options="{
        enabled: true,
        dropdownAllowAll: false,
      }" :columns="columns" :rows="rows" :sort-options="{
  enabled: false,
  initialSortBy: { field: 'username', type: 'asc' },
}" styleClass="vgt-table" slot="outsideBody">
      <template slot="column-filter" slot-scope="{ column, updateFilters }">
        <input ref="customer.full_name" class="form-control" placeholder="Search .." v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'customer.full_name'
          " @input="(value) => updateFilters(column, $refs['customer.full_name'].value)" />
        <input ref="customer.email" class="form-control" placeholder="Search .." v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'customer.email'
          " @input="(value) => updateFilters(column, $refs['customer.email'].value)" />
        <div class="input-group" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'createdAt'
          ">
          <input type="date" ref="createdAt1" class="form-control" placeholder="Search product"
            @input="(value) => updateFilters(column, $refs.createdAt1.value + '#' + $refs.createdAt2.value)" />
          <span class="input-group-text">
            -
          </span>
          <input type="date" ref="createdAt2" class="form-control" placeholder="Search product"
            @input="(value) => updateFilters(column, $refs.createdAt1.value + '#' + $refs.createdAt2.value)" />
        </div>
        <select ref="status.label" class="form-select" placeholder="Cari productCategory" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'status.label'
          " @change="(value) => updateFilters(column, $refs['status.label'].value)">
          <option />
          <option v-for="(option, key) in options" v-bind:value="option.value" :key="key" v-text="option.label" />
        </select>
      </template>
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'action'">
          <div class="row g-2 align-items-center justify-content-end" v-if="props.row.status !== 'Deleted'">
            <div class="col-auto" v-if="edit">
              <router-link :to="`/submission/edit/${props.row.id}`" class="btn btn-lg text-dark" v-b-tooltip.hover
                title="Edit">
                <i class="fas fa-pencil"></i>
              </router-link>
            </div>
            <div class="col-auto" v-if="deleteFreeSample">
              <button type="button" class="btn btn-lg text-dark" v-b-tooltip.hover title="Move to Trash"
                @click.prevent="showAlertConfirm(props.row.id)">
                <i class="fas fa-trash"></i>
              </button>
            </div>
          </div>
        </span>
        <span v-else :class="[props.row.status === 'Deleted' && 'text-muted']">
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import freesampleService from "@/service/freesample.service";
import tokenService from "@/service/token.service";
import customerService from "@/service/customer.service";
import _ from 'lodash';
export default {
  data() {
    return {
      permisions: tokenService.getPermission(),
      isLoading: false,
      options: [
        { label: 'Pending', value: 'pending' },
        { label: 'Approve', value: 'approve' },
        { label: 'Reject', value: 'reject' }
      ],
      columns: [
        {
          label: "Nama",
          field: "customer.full_name",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Email",
          field: "customer.email",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Status",
          field: "status.label",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Submitted at",
          field: "createdAt",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          type: "date",
          dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSX",
          dateOutputFormat: "yyyy-MM-dd HH:mm:ss",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
          thClass: "text-end text-nowrap",
          tdClass: "text-center text-nowrap",
        },
      ],
      rows: [],
      totalRecords: 0,
      serverParams: {
        search: '',
        take: 10,
        skip: 0,
        sort: 'desc'
      },
      errorCode: "",
      errorMessage: "",
      error: "",
      forbidenError: false,
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ skip: (params.currentPage - 1) * params.currentPerPage });
      this.loadItems();
    },
    onPerPageChange(params) {
      this.updateParams({ skip: 0 });
      this.updateParams({ take: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams();
      this.loadItems();
    },

    onColumnFilter: _.debounce(function (params) {
      let payload = {}
      if (params.columnFilters['createdAt'] && params.columnFilters['createdAt'] != '#') {
        let arrSplit = params.columnFilters['createdAt'].split('#')
        if (arrSplit[0] && arrSplit[1]) {
          payload.createdAtStartDate = arrSplit[0] ? arrSplit[0] : ''
          payload.createdAtEndDate = arrSplit[1] ? arrSplit[1] : ''
        }
      } else {
        delete this.serverParams.createdAtStartDate
        delete this.serverParams.createdAtEndDate
      }
      if (params.columnFilters['customer.email'] && params.columnFilters['customer.email'] != '') {
        payload.email = params.columnFilters['customer.email'] ? params.columnFilters['customer.email'] : ''
      } else {
        delete this.serverParams.email
      }

      if (params.columnFilters['customer.full_name'] && params.columnFilters['customer.full_name'] != '') {
        payload.name = params.columnFilters['customer.full_name']
      } else {
        delete this.serverParams.name
      }

      if (params.columnFilters['status.label'] && params.columnFilters['status.label'] != '') {
        payload.sample_state = params.columnFilters['status.label'] ? params.columnFilters['status.label'] : ''
      } else {
        delete this.serverParams.sample_state
      }
      this.updateParams(payload);
      this.loadItems();
    }, 300),
    loadItems() {
      this.isLoading = true;
      freesampleService
        .getFreeSampleList(this.serverParams)
        .then(async ({ data: { data } }) => {
          let newRows = []
          if (data.freeSample.length > 0) {
            for (const [k, v] of Object.entries(data.freeSample)) {
              let { user_id, sample_state, ...newData } = v
              let { data, accepted } = await this.loadCustomerById(user_id)
              newRows.push({
                ...newData,
                customer: data,
                status: this.options.find((r) => r.value == sample_state)
              })
            }
            this.rows = newRows
            this.totalRecords = data.totalData
          } else {
            this.rows = []
            this.totalRecords = 0
          }

        }).catch((e) => {
          if (e.response?.status !== 404) {
            // console.log(e)
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message || "Terjadi Kesalahan",
            });
          } else {
            this.rows = []
          }
        }).finally(() => {
          this.isLoading = false;
        })
    },
    loadCustomerById(id) {
      return customerService
        .getCustomerById(id)
        .then(({ data: { data } }) => {
          return Promise.resolve({ accepted: true, data: data.customer })
        }).catch((e) => {
          return Promise.reject({ accepted: false })
        })
    },
    downloadExcel() {
      this.isLoading = true;
      freesampleService.exportExcel()
        .then(({ data: { data } }) => {
          let { path } = data
          if (path) {
            window.open(this.$api_name + '/' + path, '_blank')
          }
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message.toString() || "Terjadi Kesalahan",
          });
        }).finally(() => {
          this.isLoading = false;
        })
    },

    showAlertConfirm(id) {
      this.$swal
        .fire({
          title: "",
          text: "Apakah Data Akan Di Hapus?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ya",
        })
        .then(async (result) => {
          if (result.value) {
            await this.doDelete(id);
          }
        });
    },

    doDelete(id) {
      this.isLoading = true;
      freesampleService
        .deleteFreeSampleById(id)
        .then((response) => {
          this.$swal({
            title: "Data berhasil dihapus",
            showCloseButton: true,
          }).then(() => {
            this.loadItems();
          });
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message || "Terjadi Kesalahan",
          });
          this.loadItems();
        }).finally(() => {
          this.isLoading = false;
        })
    },
  },
  computed: {
    exportExcel: function () {
      if (this.permisions.find((r) => r == 'FreeSample Export')) {
        return true
      }
      return false;
    },
    edit: function () {
      if (this.permisions.find((r) => r == 'FreeSample Patch')) {
        return true
      }
      return false;
    },
    deleteFreeSample: function () {
      if (this.permisions.find((r) => r == 'FreeSample Delete')) {
        return true
      }
      return false;
    },
  },
  mounted() {
    this.loadItems();
  },
};
</script>
