<template>
  <div>
    <div class="row align-items-center justify-content-between mb-4">
      <div class="col-auto">
        <!-- BEGIN page-header -->
        <h1 class="page-header">Social Media Setting</h1>
        <!-- END page-header -->
      </div>
      <div class="col-auto mb-3" v-if="add">
        <router-link to="/setting/social-media/add">
          <a class="btn btn-outline-primary">Add New Social Media</a>
        </router-link>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-9 col-md-8">
        <setting-nav />
        <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="totalRecords"
          :isLoading.sync="isLoading" :pagination-options="{
            enabled: true,
            dropdownAllowAll: false,
          }" :columns="columns" :rows="rows" :sort-options="{
  enabled: false,
  initialSortBy: { field: 'name', type: 'asc' },
}" styleClass="vgt-table" slot="outsideBody">
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'action'">
              <div class="row g-2 align-items-center justify-content-end" v-if="props.row.status !== 'Deleted'">
                <div class="col-auto" v-if="edit">
                  <router-link :to="`/setting/social-media/edit/${props.row.id}`" class="btn btn-lg text-dark"
                    v-b-tooltip.hover title="Edit">
                    <i class="fas fa-pencil"></i>
                  </router-link>
                </div>
                <div class="col-auto" v-if="deleteSiteSetting">
                  <button type="button" class="btn btn-lg text-dark" v-b-tooltip.hover title="Move to Trash"
                    @click="showAlertConfirm(props.row.id)">
                    <i class="fas fa-trash"></i>
                  </button>
                </div>
              </div>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
      <!-- <div class="col-lg-3 col-md-4">
        <div class="panel panel-sticky">
          <div class="panel-body">
            <div class="mb-15px">
              <p class="fw-bolder mb-1">Published Date</p>
              <p class="mb-0">22 December 2022</p>
            </div>
            <div class="mb-15px">
              <p class="fw-bolder mb-1">Last Modified Date</p>
              <p class="mb-0">22 December 2022</p>
            </div>
            <div class="mb-0">
              <p class="fw-bolder mb-1">Author</p>
              <p class="mb-0">Wanda Vu</p>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import SettingNav from './../Nav.vue'
import SiteSettingService from "@/service/sitesetting.service";
import tokenService from "@/service/token.service";
export default {
  components: {
    SettingNav,
  },
  data() {
    return {
      isLoading: false,
      columns: [
        {
          label: "Social Media Goo.n",
          field: "default_value",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
          thClass: "text-end text-nowrap",
          tdClass: "text-center text-nowrap",
        },
      ],
      rows: [],
      totalRecords: 0,
      serverParams: {
        skip: 0,
        take: 10
      },
      errorCode: "",
      errorMessage: "",
      error: "",
      forbidenError: false,
      permisions: tokenService.getPermission(),
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },
    onPerPageChange(params) {
      this.updateParams({ page: 1 });
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams();
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },
    // loadItems() {
    //   this.rows = [
    //     {
    //       id: 1,
    //       name: "Instagram",
    //     },
    //     {
    //       id: 2,
    //       name: "Twitter",
    //     },
    //     {
    //       id: 3,
    //       name: "Youtube",
    //     },
    //   ];
    // },

    loadItems() {
      // this.isLoading = true;
      SiteSettingService
        .getSiteSettingGrup('social_media')
        .then(({ data: { data } }) => {
          this.rows = data
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message || "Terjadi Kesalahan",
          });
        }).finally(() => {
          this.isLoading = false;
        })
    },

    showAlertConfirm(id) {
      this.$swal
        .fire({
          title: "",
          text: "Apakah Data Akan Di Hapus?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ya",
        })
        .then(async (result) => {
          if (result.value) {
            await this.doDelete(id);
          }
        });
    },
    async doDelete(id) {
      this.isLoading = true;
      try {
        let { resp } = await this.loadItemsById(id)
        let { accepted } = await this.loadItemsByGroup(resp.caller_param)
        let { data } = await this.deleteRequest(id)
        if (data) {
          this.loadItems()
        }
      } catch (e) {
        this.$notify({
          type: "error",
          title: "Error Message",
          text: e.response?.data?.message || "Terjadi Kesalahan",
        });
      } finally {
        this.isLoading = false;
      }
    },

    loadItemsByGroup(grup) {
      // this.isLoading = true;
      return SiteSettingService
        .getSiteSettingGrup(grup)
        .then(async ({ data: { data } }) => {
          let arrResp = []
          if (data.length > 0) {
            for (const [k, v] of Object.entries(data)) {
              arrResp.push(this.deleteRequest(v.id))
            }
          }
          return Promise.all(arrResp)
            .then((r) => Promise.resolve({ accepted: true }))
            .catch((e) => Promise.reject({ accepted: true, e }))
        }).catch((e) => Promise.reject({ accepted: true, e }))
    },
    deleteRequest(id) {
      return SiteSettingService
        .deleteSiteSettingById(id)
        .then(({ data: { data } }) => Promise.resolve({ data }))
        .catch((e) => Promise.reject({ e }))
    },
    loadItemsById(id) {
      // this.isLoading = true;
      return SiteSettingService
        .getSiteSettingById(id)
        .then(({ data: { data } }) => Promise.resolve({ accepted: false, resp: data }))
        .catch((e) => Promise.reject({ accepted: false, error: e }))
    },
  },
  computed: {
    add: function () {
      if (this.permisions.find((r) => r == 'SiteSetting Add')) {
        return true
      }
      return false;
    },
    edit: function () {
      if (this.permisions.find((r) => r == 'SiteSetting Patch')) {
        return true
      }
      return false;
    },
    deleteSiteSetting: function () {
      if (this.permisions.find((r) => r == 'SiteSetting Delete')) {
        return true
      }
      return false;
    },
  },
  mounted() {
    this.loadItems();
  },
};
</script>
