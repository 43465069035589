<template>
  <div class="product-form">
    <FormPost form-type="edit" :detail-data="form" @onValidateSuccess="doSave" :on-loading="onLoading" />
  </div>
</template>
<script>
import FormPost from "./Form.vue";
import base64Converter from "@/pages/Helper/base64";
import RedeemService from "@/service/redeem.service";
import RedeemMilestoneService from "@/service/redeemmilestones.service";
import CustomerService from "@/service/customer.service";
import RewardService from "@/service/reward.service";
import TokenService from "@/service/token.service";
import surveyService from "@/service/survey.service";
export default {
  data() {
    return {
      onLoading: false,
      optionsStatusPengirimanMilestones: [
        {
          label: 'Waiting Approval',
          value: 'waiting_approval'
        },
        {
          label: 'Processed',
          value: 'processed'
        },
        {
          label: 'Delivered',
          value: 'delivered'
        },
        {
          label: 'Arrived',
          value: 'arrived'
        },
      ],
      optionsStatusPengiriman: [
        {
          label: 'Pending',
          value: 'pending'
        },
        {
          label: 'In Review',
          value: 'in_review'
        },
        {
          label: 'Approved',
          value: 'approve'
        },
        {
          label: 'Rejected',
          value: 'reject'
        }
      ],
      form: {
        id: this.$route.params.id,
        title: "Diskon 40%",
      },
    };
  },
  components: {
    FormPost,
  },
  mounted() {
    this.loadItems(this.$route.params.id)
  },
  methods: {
    async doSave(payload) {
      let { status } = payload
      let { answers, product, category, answers_type, type, question, order, is_other } = payload.survey
      let options = []
      if (answers.length > 0) {
        for (const [k, v] of Object.entries(answers)) {
          options.push({
            option: v.option ? v.option : '',
            option_image: v.srcIcon ? await base64Converter(v.icon[0]) : null,
            is_disabled: v.disabled ? v.disabled : false
          })
        }
      }

      let request = {
        question: question ? question : null,
        type_of_question: type ? type : null,
        related_product_category_id: product ? product : 0,
        question_section: category ? category : null,
        type_of_answer: answers_type ? answers_type : null,
        order: order ? order : null,
        is_other: is_other ? is_other : false,
        options: options,
        status,
      }

      surveyService.patchSurvey(this.$route.params.id, request).then((rsp) => {
        this.$swal({
          title: "Data berhasil disimpan",
          showCloseButton: true,
        }).then(() => {
          this.$router.push({ path: '/survey/list' })
        });
      }).catch((e) => {
        if (e.response?.status === 409) {
          this.$notify({
            type: "warn",
            title: "Error Message",
            text: "Data Sudah Ada",
          });
        } else {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message || "Terjadi Kesalahan",
          });
        }
      }).finally(() =>
        this.onLoading = false)
    },


    loadItems(id) {
      surveyService
        .getSurveyById(id)
        .then(async ({ data: { data } }) => {
          let surveyOptions = []
          if (data.SurveyOption.length > 0) {
            for (const [k, v] of Object.entries(data.SurveyOption)) {
              surveyOptions.push({
                ...v,
                icon: v.option_image ? [await this.convertURLtoFile(this.loadSrcImage(v.option_image))] : null,
                srcIcon: v.option_image ? this.loadSrcImage(v.option_image) : null,
                disabled: v.is_disabled ? v.is_disabled : false
              })
            }
          }

          this.form = {
            id: this.$route.params.id,
            type: data.type_of_question ? data.type_of_question : null,
            question: data.question ? data.question : null,
            order: data.order ? data.order : null,
            product: data.related_product_category_id ? data.related_product_category_id : 0,
            category: data.question_section ? data.question_section : null,
            answers_type: data.type_of_answer ? data.type_of_answer : null,
            is_other: data.is_other ? data.is_other : false,
            answers: surveyOptions
          }
        }).catch((e) => {
          if (e.response?.status !== 404) {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message || "Terjadi Kesalahan",
            });
          } else {
            this.rows = []
          }
        }).finally(() => {
          this.isLoading = false;
        })
    },
    loadSrcImage(url) {
      return this.$api_name + '/' + url
    },
    async convertURLtoFile(url) {
      const response = await fetch(url);
      if (response.status != 200) {
        return "";
      }
      const data = await response.blob();
      const filename = url.split("/").pop(); // url 구조에 맞게 수정할 것
      const metadata = { type: `image/jpeg` };
      return new File([data], filename, metadata);
    },
  },
};
</script>
