<template>
  <form @submit.prevent="doSave('Publish')" class="pb-5 mb-4">
    <div class="row">
      <div class="col-12">
        <h1 class="page-header">
          <button class="btn btn-primary" @click.prevent="$router.go(-1)">
            <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            <i class="fa fa-arrow-left" aria-hidden="true"></i>
            Back
          </button>
          {{ formTitle }} E-commerce
        </h1>
      </div>
      <div class="col-lg-9 col-md-8">
        <div class="mb-15px">
          <input type="text" class="form-control form-control-lg" placeholder="E-commerce Name" v-model="product.name" />
          <span class="text-danger" v-if="$v.product.name.$error">Name is required</span>
        </div>
        <h3 class="fw-normal mb-15px pt-3">E-commerce Information</h3>
        <panel title="E-commerce" variant="inverse" :is-collapse="true">
          <div class="mb-15px">
            <label class="form-label">CTA #1 URL</label>
            <div class="input-group">
              <span class="input-group-text"><i class="fa-solid fa-link"></i></span>
              <input type="url" v-model="product.cta" class="form-control" placeholder="https://shopee.com/GoonIndonesia">
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <label class="form-label">Main Image</label>
            </div>
            <div class="col-md-6">
              <div class="mb-15px">
                <p class="mb-2">(dekstop version : 214x70 px, max. 1mb, jpeg, jpg, png)</p>
                <UploadImage v-model="product.images.desktop" size="1024" :src="product.images.desktopSrc"
                  :dimensions="{ width: 214, height: 70 }" @image-err-message="imgErrMessage" />
                <!-- <span class="text-danger" v-if="$v.product.images.desktop.$error">Dekstop is required</span> -->
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-15px">
                <p class="mb-2">(mobile version : 214x70 px, max. 1mb, jpeg, jpg, png)</p>
                <UploadImage v-model="product.images.mobile" size="1024" :src="product.images.mobileSrc" width="390"
                  :dimensions="{ width: 214, height: 70 }" @image-err-message="imgErrMessage" />
                <!-- <span class="text-danger" v-if="$v.product.images.mobile.$error">Mobile is required</span> -->
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="mb-15px">
                  <label class="form-label">Dekstop Alt Text</label>
                  <input type="text" v-model="product.images.alt_desktop_image" class="form-control"
                    placeholder="Alt text" />
                  <!-- <span class="text-danger" v-if="$v.product.images.alt.$error">Main Image Alt is required</span> -->
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-15px">
                  <label class="form-label">Mobile Alt Text</label>
                  <input type="text" v-model="product.images.alt_mobile_image" class="form-control"
                    placeholder="Alt text" />
                  <!-- <span class="text-danger" v-if="$v.product.images.alt.$error">Main Image Alt is required</span> -->
                </div>
              </div>
              <div class="col-md-12">
                <label class="form-label">Kategory</label>

                <select class="form-select" v-model="product.store_status">
                  <option />
                  <option v-for="(option, key) in [
                    { label: 'Online', value: 'online' },
                    { label: 'Offline', value: 'offline' }]" v-bind:value="option.value" :key="key"
                    v-text="option.label" />
                </select>
              </div>
            </div>
          </div>
        </panel>
      </div>
      <div v-if="product.author" class="col-lg-3 col-md-4">
        <div class="panel panel-sticky">
          <div class="panel-body">
            <div class="mb-15px">
              <p class="fw-bolder mb-1">Published Date</p>
              <p class="mb-0">{{ product.publishedDate ? dateFormat(product.publishedDate) : '' }}</p>
            </div>
            <div class="mb-15px">
              <p class="fw-bolder mb-1">Last Modified Date</p>
              <p class="mb-0">{{ product.lastModified ? dateFormat(product.lastModified) : '' }}</p>
            </div>
            <div class="mb-15px">
              <p class="fw-bolder mb-1">Author</p>
              <p class="mb-0">{{ product.author }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-action">
      <div class="row justify-content-end">
        <div class="col-auto">
          <button type="button" class="btn btn-outline-primary" @click.prevent="doSave('Draft')">
            <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            Save as Draft
          </button>
        </div>
        <div class="col-auto">
          <button type="submit" class="btn btn-primary">
            <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            Publish
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { defaultToolbar } from "../../../config/Helpers";
import UploadImage from "../../../components/upload/Image.vue";
import { required } from "vuelidate/lib/validators/";
import dateFormat from "@/pages/Helper/formate_date";

export default {
  components: {
    UploadImage,
  },
  props: {
    formType: {
      type: String,
      default: "add",
    },
    detailData: {
      type: Object,
      default: null,
    },
    onLoading: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    // console.log(this.detailData);
  },
  data() {
    const detail = this.detailData;
    const defaultToolbarEditor = defaultToolbar;
    return {
      dateFormat,
      optionsCategory: ["Pants L", "Outer Limit"],
      optionsType: ["Pants L", "Outer Limit"],
      customToolbar: defaultToolbarEditor,
      product: {
        name: detail ? detail.name : null,
        cta: detail ? detail.cta : null,
        images: {
          desktop: detail ? detail.desktop : null,
          mobile: detail ? detail.mobile : null,
          desktopSrc: detail ? detail.desktopSrc : '',
          mobileSrc: detail ? detail.mobileSrc : '',
          alt: detail ? detail.alt : null,
        },
        publishedDate: detail ? detail.publishedDate : null,
        lastModified: detail ? detail.lastModified : null,
        author: detail ? detail.author : null,
      },
    };
  },
  computed: {
    formTitle() {
      return this.formType === "add" ? "Add" : "Edit";
    },
  },
  methods: {
    doSave(status) {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const payload = {
          product: this.product,
          status
        };

        this.$emit("onValidateSuccess", payload);
      } else {
        this.$notify({
          type: "warn",
          title: "Form",
          text: "Form Belum Lengkap",
        });
      }
    },
    imgErrMessage(mssg) {
      this.$notify({
        type: "error",
        title: "Image Notification",
        text: mssg,
      });
    },
  },
  watch: {
    'detailData'(newVal) {
      this.product = newVal
    }
  },
  validations: {
    product: {
      name: { required },
      alt: {},
    },
  },
};
</script>
