<template>
  <div class="product-form">
    <FormSocmed form-type="add" @onValidateSuccess="doSave" />
  </div>
</template>
<script>
import FormSocmed from "./Form.vue";
import SiteSettingService from "@/service/sitesetting.service";
import base64Converter from "@/pages/Helper/base64";
export default {
  components: {
    FormSocmed,
  },

  methods: {
    async doSave(payload) {
      // Submit here
      let { form } = payload
      let { imagesSrc, ...newForm } = form
      let arrReq = []
      for (const v in newForm) {
        arrReq.push(this.addRequest({
          caller_param: v + '_' + newForm.name.toLowerCase(),
          title: v + '_' + newForm.name.toLowerCase(),
          description: v + '_' + newForm.name.toLowerCase(),
          default_value: (v == 'images') ? await base64Converter(newForm[v][0]) : newForm[v],
          attribute_value: "",
          type: (v == 'images') ? "image" : "text",
          grup: newForm.name.toLowerCase()
        }))
      }

      arrReq.push(this.addRequest({
        caller_param: newForm.name.toLowerCase(),
        title: newForm.name.toLowerCase(),
        description: newForm.name.toLowerCase(),
        default_value: newForm.name,
        attribute_value: "",
        type: "text",
        grup: "social_media"
      }))

      Promise.all(arrReq)
        .then((r) => {
          this.$swal({
            title: "Data berhasil disimpan",
            showCloseButton: true,
          }).then(() => {
            // this.$router.push({ path: '/setting/social-media' })
          });
        }).catch((e) => {
          if (e.response?.status === 409) {
            this.$notify({
              type: "warn",
              title: "Error Message",
              text: "Data Sudah Ada",
            });
          } else {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message || "Terjadi Kesalahan",
            });
          }
        }).finally(() => {
          this.$router.push({ path: '/setting/social-media' })
        })
    },

    addRequest(req) {
      return SiteSettingService
        .postSiteSetting(req)
        .then((response) => {
          return Promise.resolve({ ...response })
        }).catch((e) => {
          return Promise.reject({ ...e })
        })
    },
  },
};
</script>
