import FileService from "../service/file.service";
export default {
  namespaced: true,
  state: {},
  actions: {
    upload(_ctx, content) {
      let formData = new FormData()
      formData.append('file', content[0], content[0].name)
      return FileService.postFileUpload(formData).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
  },
  getters: {},
  mutations: {},
};
