<template>
  <div class="product-form">
    <FormPost form-type="add" @onValidateSuccess="doSave" :on-loading="onLoading" />
  </div>
</template>
<script>
import FormPost from "./Form.vue";
import faqService from "@/service/faq.service";
import base64Converter from "@/pages/Helper/base64";
import slugify from "@/pages/Helper/slug";
import moment from "moment";

export default {
  components: {
    FormPost,
  },
  data() {
    return {
      onLoading: false
    }
  },
  methods: {
    async doSave(payload) {
      this.onLoading = true
      let { faq, status } = payload
      if (faq) {

        let req = {
          title: faq.title ? faq.title : null,
          category_id: faq.category.id ? faq.category.id : null,
          answer: faq.description ? faq.description : null,
          slug: slugify(faq.title),
          status: status,
        }

        faqService.postFaq(req).then((rsp) => {
          this.$swal({
            title: "Data berhasil disimpan",
            showCloseButton: true,
          }).then(() => {
            this.$router.push({ path: '/faq/list' })
          });
        }).catch((e) => {
          if (e.response?.status === 409) {
            this.$notify({
              type: "warn",
              title: "Error Message",
              text: "Data Sudah Ada",
            });
          } else {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message || "Terjadi Kesalahan",
            });
          }
        }).finally(() =>
          this.onLoading = false)

      }
    },
    dateFormat(date) {
      return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD')
    }
  },
};
</script>
